.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.bullet-list-blue > li {
  position: relative;
  list-style-type: none;
}
.bullet-list-blue > li::before {
  content: "";
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  left: -20px;
  top: 11px;
  background-color: #0366d6;
}


.pricing-box {
    padding: 50px 40px 0px 40px;
    border: 1px solid #ecf0f5;
    border-radius: 8px;
    box-shadow: 0 0 1.25rem rgba(108,118,134,.1)!important;
    background-color: #ffffff;
    position: relative;
}

.pricing-border {
    width: 100%;
    height: 1px;
    margin-top: 35px;
    background-color: #ecf0f5;
}

.price-active {
    box-shadow: 0 0rem 4rem rgba(0,0,0,.1)!important;
}

.ribbon-box {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right; 
}
    
.ribbon-box  span {
    font-size: 13px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
        background: #f65353;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    position: absolute;
    top: 19px;
    left: -21px;
}

.ribbon-box span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent; 
}

.ribbon-box  span:after {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent; 
}


.wave-effect {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 150px;
    overflow: hidden;
}

.shape-one {
    z-index: 15;
    opacity: 0.5;
}
.shape-two {
    z-index: 10;
    opacity: 0.75;
}
.shape-three {
    z-index: 5;
}

body #firebaseui_container {
  background: transparent!important;
}

#header-grant-private  {
  display: inline-block;
}

body #firebaseui_container {
  display: inline-block;
}
body .firebaseui-idp-button {
  box-shadow: none;
  border: none;
}

body .firebaseui-list-item {
  margin: 0!important;
  text-align: left;
}

body .login-as-button-primary .firebaseui-idp-text {
  color: white!important;
  font-weight: bold;
  padding: 10px;

}

body .login-as-button-primary .firebaseui-idp-button {
  padding: 6px 16px;
}

body .login-as-link .firebaseui-idp-text {
  font-weight: normal;
    color: #0366d6!important;
    text-decoration: underline;
    font-size: inherit;
}

body .firebaseui-container {
  display: inline-block;
}

.wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
}

.waves-shape {
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.wave-one {
    background-size: 50% 100px;
}

.wave-anim .wave-one {
  animation: move-wave 30s;
   -webkit-animation: move-wave 30s;
   -webkit-animation-delay: 10s;
   animation-delay: 10s;
}
.wave-two {
    background-size: 50% 120px;
}
.wave-anim .wave-two {
    animation: move_wave 100s linear infinite;
}
.wave-three {
    background-size: 50% 100px;
}
.wave-anim .wave-three {
    animation: move_wave 150s linear infinite;
}


@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}


#release-notes span,
#release-notes a,
#release-notes code {
  font-size: 1rem;
}
#release-notes ul {
  margin-left: 15px;
}

.shrink-to-content,
.shrink-to-content table {
  display: inline-block;
}

.django-doctor-before-after a {
    text-decoration: underline;
}

.django-doctor-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}
.django-doctor-masonry-grid_column {
  background-clip: padding-box;
}

.django-doctor-masonry-grid_column > div {
  margin-bottom: 30px;
  position: relative;
}


.django-doctor-before-after [class$="-gutter"]:first-child {
    display: none;
}

@media (max-width: 390px){

    .django-doctor-before-after [class$="-diff-container"] pre {
        white-space: pre;
    }
}